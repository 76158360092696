<template>
  <b-overlay :show="isFetchingCmsSite">
    <b-row>
      <b-col class="text-right mb-2">
        <b-button variant="success" @click="$bvModal.show('cms-create-banner')"
          >New Banner</b-button
        >
      </b-col>
    </b-row>
    <b-table :fields="fields" :items="cmsBanners" responsive>
      <template #cell(#)="data">
        <div>
          {{ data.index + 1 }}
        </div>
      </template>
      <template #cell(imageSrc)="data">
        <b-img :src="data.value" width="auto" height="150px"></b-img>
      </template>
      <template #cell(title)="data">
        <div>
          {{ data.value || '-' }}
        </div>
      </template>
      <template #cell(id)="data">
        <div>
          <b-button
            size="sm"
            variant="danger"
            @click="onRemoveBanner(data.value)"
            >Remove</b-button
          >
        </div>
      </template>
    </b-table>
    <CreateCmsBanner />
  </b-overlay>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    CreateCmsBanner: () => import('./create-banner.vue'),
  },
  data() {
    return {
      fields: [
        '#',
        {
          key: 'title',
          label: 'Title',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'imageSrc',
          label: 'Image',
        },
        {
          key: 'position',
          label: 'Position',
        },
        {
          key: 'id',
          label: 'Actions',
          class: 'text-right',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['cmsBanners', 'siteId']),
    ...mapState({
      isFetchingCmsSite: (state) => state.cms.isFetchingCmsSite,
    }),
  },
  methods: {
    ...mapActions(['removeCmsBanner']),
    onRemoveBanner(bannerId) {
      this.$bvModal.msgBoxConfirm('Are you sure?').then((value) => {
        if (value) {
          this.removeCmsBanner({ siteId: this.siteId, bannerId })
        }
      })
    },
  },
}
</script>
